* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    border: none;
    outline: none;
}
html, body {
    background-color: #F7F8F8;
    font-family: Calibri, sans-serif;
}
header {
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 72px;
    padding: 0 32px;
    margin-bottom: 16px;
    box-shadow: 0px 6px 8px rgba(0, 0, 0, .03);

    h1 {
        margin: 0 16px;
        font-size: 22px;
        font-weight: bold;
        letter-spacing: -0.7px;
        line-height: 34px;
        color: #484A4D;
    }
    p {
        font-size: 14px;
        line-height: 1.5;

        a, a:visited {
            text-decoration: none;
            color: #0095d9;
        }
        a:hover, a:active {
            text-decoration: underline;
            cursor: pointer;
        }
    }
  }
  .loading-text {
      margin: 32px;
      display: block;
      text-align: center;
      color: #777c87;
  }

  // provisorio para o link aberto
  .iframe-powerbi {
    display: block;
    background-color: #fff;
    height: 720px;
    width: 1280px;
    margin: 16px auto;
    border-radius: 8px;
    overflow: hidden;
  }